/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

@import "./assets/fonts/material-icons.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
    --main-bg-color: #FFFFFF;
    --main-disabled-color: #B4B4B4;
}

.p-float-label > .ng-invalid.ng-touched + label {
  color: #e24c4c;
}

.ng-touched.ng-invalid {
  border-color: #e24c4c !important;
}
