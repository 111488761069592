@mixin about-theme($theme) {
    $primary: map-get($theme, primary);
    $on-primary: white;
    $accent: map-get($theme, accent);
    $on-accent: black;
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .about--header,
    .about--content {
        color: mat-color($primary);
    }
}
