@import '@angular/material/theming';

@import './about/about-theme.scss';


@mixin main-theme($theme) {
    $primary: map-get($theme, primary);
    $on-primary: white;
    $accent: map-get($theme, accent);
    $on-accent: black;
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    mat-sidenav {
        a {
            color: mat-color($primary) !important;
        }
    }

    mat-sidenav-content {
        background-color: var(--main-bg-color);
    }

    .rrps-snackbar {
        background-color: #FFFFFF;
        color: #000000;
        button {
            background-color: mat-color($primary);
            color: #FFFFFF;
        }
    }

    // own components
    @include about-theme($theme);
}
