/* http://mcg.mbitson.com/#!?mcgpalette0=%2310069f */
$rrps-primary-color: (
    50 : #e2e1f3,
    100 : #b7b4e2,
    200 : #8883cf,
    300 : #5851bc,
    400 : #342bad,
    500 : #10069f,
    600 : #0e0597,
    700 : #0c048d,
    800 : #090383,
    900 : #050272,
    A100 : #a2a1ff,
    A200 : #6f6eff,
    A400 : #3d3bff,
    A700 : #2421ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);