/* Custom Theming for Angular Material
For more information: https://material.angular.io/guide/theming */
@import "@angular/material/theming";
/* Plus imports for other components in your app. */
@import "./rrps-primary-color.scss";
@import "./rrps-accent-color.scss";
@import "src/app/app-theme.scss";

/* Include the common styles for Angular Material. We include this here so that you only
have to load a single css file for Angular Material in your app.
Be sure that you only ever include this mixin once! */
@include mat-core();

$rrps-primary: mat-palette($rrps-primary-color);
$rrps-accent: mat-palette($rrps-accent-color, 500, 900, A100);
$rrps-warn: mat-palette($mat-red);

$rrps-theme: mat-light-theme($rrps-primary, $rrps-accent, $rrps-warn);

@mixin rrps-theme($theme) {
  $primary: map-get($theme, primary);
  $on-primary: white;
  $accent: map-get($theme, accent);
  $on-accent: black;
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  @include angular-material-theme($theme);

  /* Firefox-only */
  * {
    scrollbar-width: thin;
    //scrollbar-color: mat-color($primary) var(--main-bg-color);
  }

  /* Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 0.5rem;
  }
  *::-webkit-scrollbar-track {
    background: var(--main-bg-color);
    box-shadow: inset 0 0 5px grey;
  }
  *::-webkit-scrollbar-thumb {
    //background-color: mat-color($primary);
    background-color: var(--main-disabled-color);
  }

  .warn--foreground {
    color: mat-color($warn);
  }

  .router-link-active,
  .router-link-active:hover {
    box-sizing: border-box;
    color: mat-color($primary) !important;
    background-color: #ececec !important;
    border-right: 5px solid #10069f;
    //background-color: #B4B4B4 !important;
  }

  button:disabled {
    color: $on-primary !important;
    background-color: var(--main-disabled-color) !important;
  }

  .rrps--subheading {
    color: mat-color($primary);
    // font-size: 20px !important;
    // font-weight: 200 !important;
}

  /* own components */
  @include app-theme($theme);
}

@include rrps-theme($rrps-theme);
