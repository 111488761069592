/* http://mcg.mbitson.com/#!?mcgpalette0=%23e60003 */
$rrps-accent-color: (
    50 : #fce0e1,
    100 : #f8b3b3,
    200 : #f38081,
    300 : #ee4d4f,
    400 : #ea2629,
    500 : #e60003,
    600 : #e30003,
    700 : #df0002,
    800 : #db0002,
    900 : #d50001,
    A100 : #fffcfc,
    A200 : #ffc9c9,
    A400 : #ff9696,
    A700 : #ff7d7d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);